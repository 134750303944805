const getFullUrl = path => `/.netlify/functions/${path}`

let activeRequests = []

const onLoad = (xhr, resolve, reject) => {
  if (xhr.status < 200 || xhr.status >= 300) {
    reject({ status: xhr.status, response: xhr.response })
  } else {
    resolve(JSON.parse(xhr.response))
  }
}

const handleRequest = (method, path, body, options = {}) => {
  if (options.cancelPreviousRequests) {
    const functionUsed = path.split('?')[0]
    activeRequests = activeRequests.filter(req => {
      if (req.url.split('?')[0] === functionUsed) {
        req.xhr.abort()
        return false
      }
      return true
    })
  }
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    const url = getFullUrl(path)
    xhr.open(method, url)
    activeRequests.push({ url: path, xhr: xhr })
    xhr.onload = () => onLoad(xhr, resolve, reject)
    if (body) {
      xhr.send(body)
    } else {
      xhr.send()
    }
  })
}

module.exports = {
  get(path, options) {
    return handleRequest('GET', path, undefined, options)
  },
  post(path, body, options) {
    return handleRequest('POST', path, body, options)
  },
}
