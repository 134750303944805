import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { contactPropType } from 'lib/prop-types'
import GclidField from 'components/GclidField'

const ContactFormFields = props => {
  return (
    <Fragment>
      <div className="contact__box">
        <div className="contact__data">
          <div className="hm-input hm-form--half">
            <label htmlFor="firstName" className="hm-form__label">
              Vorname <abbr title="Dieses Feld wird benötigt">*</abbr>
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="text"
              name="firstName"
              id="firstName"
              value={props.state.firstName}
              autoFocus={true}
              required
            />
          </div>
          <div className="hm-input hm-form--half">
            <label htmlFor="lastName" className="hm-form__label">
              Nachname <abbr title="Dieses Feld wird benötigt">*</abbr>
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="text"
              name="lastName"
              id="lastName"
              value={props.state.lastName}
              required
            />
          </div>
          <div className="hm-input hm-form--stretch">
            <label htmlFor="street" className="hm-form__label">
              Straße und Hausnummer
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="text"
              name="street"
              id="street"
              value={props.state.street}
            />
          </div>
          <div className="hm-input hm-form--one-third">
            <label htmlFor="zipCode" className="hm-form__label">
              PLZ
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="number"
              name="zipCode"
              id="zipCode"
              min="0000"
              max="9999"
              value={props.state.zipCode}
            />
          </div>
          <div className="hm-input hm-form--two-thirds">
            <label htmlFor="city" className="hm-form__label">
              Ort
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="text"
              name="city"
              id="city"
              value={props.state.city}
            />
          </div>
          <div className="hm-input hm-form--half">
            <label htmlFor="email" className="hm-form__label">
              E-Mail <abbr title="Dieses Feld wird benötigt">*</abbr>
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="email"
              name="email"
              id="email"
              value={props.state.email}
              required
            />
          </div>
          <div className="hm-input hm-form--half">
            <label htmlFor="phone" className="hm-form__label">
              Telefon <abbr title="Dieses Feld wird benötigt">*</abbr>
            </label>
            <input
              onChange={e => props.handleChange(e)}
              type="tel"
              name="phone"
              id="phone"
              value={props.state.phone}
              required
            />
          </div>
        </div>
        <div className="hm-input hm-form--stretch">
          <label htmlFor="notes" className="hm-form__label">
            Ihre Nachricht
          </label>
          <textarea
            onChange={e => props.handleChange(e)}
            name="notes"
            id="notes"
            value={props.state.notes}
          />
        </div>
      </div>
      <GclidField />
    </Fragment>
  )
}

ContactFormFields.propTypes = {
  state: contactPropType.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default ContactFormFields
