import React from 'react'
import createStore from 'lib/util/create-store'

const gclidStore = createStore('gclid')

// fancy Google <-> Salesforce stuff: https://support.google.com/google-ads/answer/6299296#web_to_lead

const expiryPeriod = 90 * 24 * 60 * 60 * 1.0 // Ablauf nach 90 Tagen in Millisekunden

class GclidField extends React.Component {
  constructor() {
    super()
    this.state = { gclid: this.getGclidValue() }
  }

  getParam(key) {
    // TODO: window is undefined during build
    if (typeof window !== 'undefined') {
      const match = RegExp('[?&]' + key + '=([^&]*)').exec(
        window.location.search
      )
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    }
  }

  getGclidValue() {
    const gclidParam = this.getParam('gclid')
    let gclidRecord = null

    const gclsrcParam = this.getParam('gclsrc')
    const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1

    if (gclidParam && isGclsrcValid) {
      gclidRecord = {
        value: gclidParam,
        expiryDate: new Date().getTime() + expiryPeriod,
      }
      gclidStore.save(gclidRecord)
    }

    const gclid = gclidRecord || gclidStore.load()
    const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate

    if (isGclidValid) {
      return gclid.value
    }
  }

  render() {
    return (
      <input type="hidden" name="GCLID" id="GCLID" value={this.state.gclid} />
    )
  }
}

export default GclidField
