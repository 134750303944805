import React from 'react'
import Helmet from 'react-helmet'

const RECAPTCHA_SITE_KEY = '6Lctu9EZAAAAAMaHHH1P0HXRxXLji009rRWMi9AD'

export const generateCaptchaToken = () => {
  if (typeof window === 'undefined' || !window.grecaptcha) {
    console.error('grecaptcha is undefined')
    return false
  }

  return new Promise((resolve, reject) =>
    window.grecaptcha.ready(() => {
      return window.grecaptcha
        .execute(RECAPTCHA_SITE_KEY, {
          action: 'submit',
        })
        .then(resolve)
        .catch(reject)
    })
  )
}

const Script = () => (
  <Helmet>
    <script
      src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}
    ></script>
  </Helmet>
)

export default Script
