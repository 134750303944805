import React, { Fragment } from 'react'
import createStore from '../lib/util/create-store'
import { phoneNumber } from '../lib/phone-number'
import ContactFormFields from '../components/ContactFormFields'
import Button from '../components/Button'
import Layout from '../components/Layout'
import Link from '../components/Link'
import SiteMetadata from 'lib/SiteMetadata'
import { siteDataPropType, urlLocationPropType } from 'lib/prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPhone,
  faExclamation,
  faSpinner,
  faEnvelopeOpen,
} from '@fortawesome/free-solid-svg-icons'
import invokeNetlifyLambda from 'lib/invoke-netlify-lambda'
import Recaptcha, { generateCaptchaToken } from 'lib/recaptcha'
import pushDataLayer from 'lib/push-data-layer'
import { Helmet } from 'react-helmet'
import { withPrefix } from 'gatsby'

const contactStore = createStore('contact')

const defaultContact = {
  firstName: '',
  lastName: '',
  street: '',
  zipCode: '',
  city: '',
  email: '',
  phone: '',
  notes: '',
}

class Consulting extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...(contactStore.load() || defaultContact),
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      contactStore.save(this.state)
    })
  }

  render() {
    return (
      <Layout>
        <Helmet title="Kontaktinformationen">
          <meta
            name="description"
            content="Nehmen Sie Kontakt zu uns auf. Ob per Mail, Telefon oder Kontaktformular - wir sind immer für Sie erreichbar. Jetzt kontaktieren!"
          />
        </Helmet>
        <Recaptcha />
        <div className="section bg--primary-grey">
          <div className="section__inner section--padded-small">
            <div className="wrap__box--pad-l columns__box bt__3--dark">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  pushDataLayer('set', 'user_data', {
                    email: this.state.email,
                    phone_number: this.state.phone,
                  })
                  pushDataLayer('event', 'contact', { type: 'Kontakt' })
                  const GCLID = e.target?.GCLID?.value
                  generateCaptchaToken().then(token => {
                    this.setState({
                      success: false,
                      error: false,
                      isSubmitting: true,
                    })
                    invokeNetlifyLambda
                      .post(
                        'pardot-submit',
                        JSON.stringify({
                          ...this.state,
                          typeOfForm: 'Contact',
                          captchaToken: token,
                          GCLID,
                        })
                      )
                      .then(() => {
                        this.setState({
                          success: true,
                          error: false,
                          isSubmitting: false,
                        })
                      })
                      .catch(e => {
                        console.error(e)
                        this.setState({
                          error: true,
                          success: false,
                          isSubmitting: false,
                        })
                      })
                  })
                }}
                className="column--half"
              >
                <h2>Wie können wir Sie erreichen?</h2>
                <ContactFormFields
                  handleChange={data => this.handleChange(data)}
                  state={this.state}
                />
                {this.state.error ? (
                  <div className="callout callout--warning mb3">
                    <span className="callout__icon">
                      <FontAwesomeIcon icon={faExclamation} />
                    </span>
                    <span className="callout__text">
                      Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
                    </span>
                  </div>
                ) : this.state.success ? (
                  <Fragment>
                    <div className="callout callout--success mv3">
                      <span className="callout__icon">
                        <FontAwesomeIcon icon={faEnvelopeOpen} />
                      </span>
                      <span className="callout__text">
                        Danke! Wir haben Ihre Nachricht erhalten und machen uns
                        nun an die Arbeit. Sie hören in Kürze von uns.
                      </span>
                    </div>
                    <div className="contact__success--cta">
                      <Link to="/konfigurator" className="btn btn--primary">
                        Wärmepumpen Check
                      </Link>
                      <div>
                        Bitte füllen Sie doch auch den Heizungscheck aus, um uns
                        mehr Infos zu geben.
                      </div>
                      <Link
                        to="/photovoltaik-check"
                        className="btn btn--primary"
                      >
                        Photovoltaik Check
                      </Link>
                      <div>
                        Bitte füllen Sie doch auch den Solarcheck aus, um uns
                        mehr Infos zu geben.
                      </div>
                    </div>
                  </Fragment>
                ) : null}
                <div className="w-100 tr">
                  {this.state.isSubmitting ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="fa-spin margin--r05"
                    />
                  ) : null}
                  {this.state.success ? null : (
                    <Button
                      className="btn btn btn--primary"
                      disabled={this.state.isSubmitting}
                    >
                      Senden
                    </Button>
                  )}
                </div>
                {this.state.error || null}
              </form>
              <div className="column--half">
                <h1>
                  Heizungsmacher - Ihre Spezialisten für Wärmepumpen und
                  Photovoltaik in der Schweiz
                </h1>
                <p>
                  Wir übernehmen die gesamte Planung und Koordination, damit Sie
                  die beste Wärmepumpe und Photovoltaikanlage für Ihr Haus
                  bekommen.
                </p>
                <div className="contact__arguments">
                  {[
                    {
                      title: 'Energiekosten sparen',
                      text: 'dank Abstimmung auf Ihren Warmwasser- und Heizverbrauch',
                      icon: 'piggy-bank',
                    },
                    {
                      title: 'Passendes System',
                      text: 'aus Wärmepumpe und Photovoltaik für Ihr Haus',
                      icon: 'house-analysis',
                    },
                    {
                      title: 'Aktives Online-Monitoring',
                      text: 'und Einstellung des Systems auf Ihre Bedürfnisse',
                      icon: 'monitoring',
                    },
                    {
                      title: 'Lokale Installateure',
                      text: 'mit Qualitätsanspruch',
                      icon: 'award',
                    },
                    {
                      title: '100% markenunabhängige Beratung',
                      text: 'und bestes Preis-Leistungs-Verhältnis',
                      icon: 'factory',
                    },
                    {
                      title: 'Service',
                      text: 'rund um die Wärmepumpen- und Photovoltaikanlage auch nach der Installation',
                      icon: 'service',
                    },
                  ].map(element => (
                    <div key={element.icon} className="contact__argument">
                      <img
                        src={withPrefix(`/icons/${element.icon}.svg`)}
                        className="contact__argument--icon"
                      />
                      <div className="contact__argument--text">
                        <h3 className="contact__argument--title">
                          {element.title}
                        </h3>{' '}
                        {element.text}
                      </div>
                    </div>
                  ))}
                </div>
                <p className="mt2">
                  Sie haben noch Fragen? Wir beraten Sie kostenlos und
                  unverbindlich!
                </p>
                <p>
                  Jetzt ganz einfach das{' '}
                  <strong>Kontaktformular absenden</strong> und wir melden uns
                  schnellstmöglich bei Ihnen. Sie können uns auch{' '}
                  <strong>telefonisch</strong> erreichen oder uns eine{' '}
                  <strong>E-Mail</strong> schreiben.
                </p>
                <div className="mt3">
                  <a
                    href={`tel:+41${phoneNumber
                      .substring(1)
                      .replace(/ /g, '')}`}
                    className="contact__link"
                  >
                    <FontAwesomeIcon
                      icon={faPhone}
                      className="link__icon--left"
                    />
                    <span>{phoneNumber}</span>
                  </a>
                </div>
                <div className="mv3">
                  <a
                    href="mailto:info@heizungsmacher.ch"
                    className="contact__link"
                  >
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="link__icon--left"
                    />
                    <span>info@heizungsmacher.ch</span>
                  </a>
                </div>
                <p>
                  Wir freuen uns auf Ihre Anfrage.
                  <br />
                  Ihre Heizungsmacher
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

const ConsultingSite = props => (
  <SiteMetadata render={data => <Consulting data={data} {...props} />} />
)

export default ConsultingSite

Consulting.propTypes = {
  data: siteDataPropType,
  location: urlLocationPropType,
}
